import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  InputBase,
  Pagination,
} from "@mui/material";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../Articles/Articles.css";
import { ApiLink } from "../Utils/Apilink";
import { CommonSetupActions } from "../../Slices/Commonslice";
import CommonLoader from "../CommonComponent/CommonLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = {
  customScrollbar: {
    // overflowY: "auto",
    height: "400px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ec1b24",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#d1191f",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
      width: "0",
      height: "0",
    },
    // scrollbarColor: "#ec1b24 #f1f1f1",
    // scrollbarWidth: "thin",
  },
};

export const Articles = () => {
  // const [isExpanded, setIsExpanded] = useState(false);
  // const [isTruncated, setIsTruncated] = useState(false);
  const [blob, setBlob] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [debouncedSearchItem, setDebouncedSearchItem] = useState(searchItem);
  const [activeBlog, setActiveBlog] = useState();
  const textRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controllerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBlobs = blob && blob.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
      window.location.reload();
    }
  }, []);

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/[\s]+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, ""); // Remove all non-word chars
  };

  const fetchBlog = (searchitem) => {
    setBlob([]);
    setActiveBlog();
    setLoading(true);

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    controllerRef.current = new AbortController();
    const a = searchitem ? searchitem.trim() : "";

    const link2 =
      searchitem || searchItem !== ""
        ? `${ApiLink}articles/${a}`
        : ApiLink + "articles";
    fetch(link2, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      signal: controllerRef.current.signal,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {

        if (response.length === 0) {
          setSearchItem("");
        }
        setBlob(response);

        setActiveBlog(response[0]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   fetchBlog();
  // }, []);

  const searchOptions = [
    { label: "option1", id: 1 },
    { label: "option2", id: 2 },
    { label: "option 3", id: 3 },
    { label: "option4", id: 4 },
    { label: "option5", id: 5 },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchItem(searchItem);
    }, 500); // Adjust the timeout value (500ms here) to your preference

    return () => clearTimeout(timer); // Clear the timeout on each change
  }, [searchItem]);

  useEffect(() => {
    if (debouncedSearchItem) {
      fetchBlog(debouncedSearchItem); // Call API after debounced search term is set
    }
  }, [debouncedSearchItem]);

  const handleSearchChange = (e) => {
    setSearchItem(e.target.value);
  };

  useEffect(() => {
    if (searchItem === "" || searchItem === undefined) {
      fetchBlog();
    }
  }, [searchItem]);

  // useEffect(() => {
  //   if (textRef.current) {
  //     setIsTruncated(
  //       textRef.current.scrollHeight > textRef.current.clientHeight
  //     );
  //   }
  // }, [activeBlog]);
  // const handleToggle = () => {
  //   setIsExpanded(!isExpanded);
  // };

  const parseHtml = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const firstP = tempDiv.querySelector("p");
    return firstP.innerHTML;
  };
  const handleImageClick = (postTitle) => {
    dispatch(CommonSetupActions.setActiveArticle(activeBlog));
    // navigate(`/articles/${activeBlog.post_title}`)
    const slug = slugify(postTitle); // Convert the title to a slug
    sessionStorage.clear();
    navigate(`/articles/${slug}`); // Navigate with the slug
  };
  return (
    <LayoutComponent>
      <Box
        className="articles_mainContainer"
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          padding: "5%",
          boxSizing: "border-box",
          // paddingBottom: 0,
        }}
      >
        <Box
          className="articles_mainContainer_sub"
          sx={{ padding: 0, 
            // marginBottom: "30px"
           }}
        >
          {/* blogs heading and search bar main div */}
          <Box
            className="blog_heading_box"
            sx={{
              // marginTop:"3%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // margin
            }}
          >
            {/* blogs heading */}
            <Box>
              <Typography
                className="blog-head-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 800,
                  fontSize: "60px",
                  color: "#000000",
                  lineHeight: "50px",
                  textDecoration: "underline",
                  textDecorationColor: "#E30000",
                  textUnderlineOffset: "10px",
                  textDecorationThickness: "4px",
                }}
              >
                Articles
              </Typography>
            </Box>

            {/* Autocomplete search bar */}

            <Box
              className="auto-articles-search-section"
              sx={{
                width: "350px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#EDEDED",
                borderRadius: "44px",
                padding: "5px 10px",
              }}
            >
              {/* Search Icon */}
              <SearchIcon
              className="article-search-icon"
                sx={{
                  color: "black",
                  marginRight: "8px",
                  fontSize: "18px",
                  height: "25px",
                  width: "25px",
                }}
              />

              {/* Input Field */}
              <InputBase
              className="article-search-input-typo"
                placeholder="Search for anything"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "12px",
                  color: "#646464",
                  width: "100%",
                }}
                value={searchItem}
                onChange={handleSearchChange}
              />
            </Box>
          </Box>

          {/* <hr
            style={{
              marginTop: "2%",
              border: "0.7px solid #D9D9D9",
            }}
          /> */}
          <hr
            className="war-hr-line"
            style={{
              width: "100%",
              marginTop: "2%",
            }}
          />
            {/* <> */}
              {/* <Box
                className="article_activeblog_main"
                sx={{
                  width: "296px",
                  height: "35px",
                  marginTop: "25px",
                }}
              >
                <Typography
                  className="featured-articles-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "34px",
                    lineHeight: "47px",
                    color: "#55120B",
                   
                  }}
                >
                  Feature Article
                </Typography>
              
              </Box> */}

{loading ? (
 <Box
 sx={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   height: "50vh",
 }}
>
 <CommonLoader
   open={loading}
   handleClose={() => setLoading(false)}
 />
</Box>
): activeBlog &&(
              <Box
                className="article_active_blog_container"
                sx={{
                  width: "100%",
                  height: "auto",
                  marginTop: "30px",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  // marginBottom: "7%",
                  // justifyContent:'center'
                }}
              >
                {/* blog title */}
                {activeBlog && (
                  <Box
                    className="article_activeblog_title"
                    sx={{
                      width: "49%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      className="article-activeBlog-title-typo"
                      variant="h4"
                      sx={{
                        fontFamily: "DM Sans",
                        fontWeight: 700,
                        fontSize: "27px",
                        lineHeight: "50px",
                        color: "#0E1415",
                        // textDecoration: 'underline',
                        // textDecorationColor: '#E30000',
                        // textUnderlineOffset: '20px',
                        // textDecorationThickness:'3.5px',
                      }}
                    >
                      {activeBlog.post_title}
                    </Typography>
                  </Box>
                )}
                {/* selected blog details and other blogs */}
                <Box
                  className="Article_inner_container"
                  sx={{
                    width: "100%",

                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    // marginTop: "20px",
                    padding: "2px",
                    gap: 10,
                  }}
                >
                  {/* active blog */}
                  <Box
                    className="article_activeblog_text_container"
                    sx={{
                      width: "50%",
                      // height: "630px",
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    {/* Author and Date */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Date */}
                      {activeBlog && (
                        <Box
                          sx={{
                            display: "flex",
                            // alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography className="active-article-article-typo"
                            sx={{
                              fontFamily: "DM Sans",
                              fontWeight: 700,
                              fontSize: "16px",
                              color: "#EC1B24",
                            }}
                          >
                            Article
                          </Typography>
                          <Box className="article-date-hr"
                            sx={{
                              width: "2px",
                              height: "20px",
                              backgroundColor: "#0E1415",
                            }}
                          />
                          <Typography
                          className="active-article-date-typo"
                            sx={{
                              fontFamily: "DM Sans",

                              fontWeight: 400,
                              fontSize: "16px",
                              color: "#0E1415",
                            }}
                          >
                            {activeBlog.new_date}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    {/* Blog Image and Date */}
                    {activeBlog && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // gap:2,
                          // justifyContent: "flex-end",
                          // alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Box
                          className="active-blog-img-main-div"
                          sx={{
                            height: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleImageClick(activeBlog.post_title)
                          }
                        >
                          <img
                            src={activeBlog.post_image}
                            alt={activeBlog && activeBlog.post_title}
                            style={{
                              width: "100%",
                              // height:'100%',
                              // height: "auto",
                              // maxHeight: "420px",
                              borderRadius: "12px",
                            }}
                          />
                        </Box>
                        <Typography
                                  className="article-active-blog-body-typo"
                                  ref={textRef}
                          sx={{
                            fontWeight: 500,
                            fontFamily: "DM Sans",
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "#0B0F11",
                            marginTop: "10px",
                            textAlign: "left",
                            display: "-webkit-box",
                            // WebkitLineClamp: isExpanded ? "unset" : 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                          }}
                        >
                          <div
                            style={{ fontFamily: "DM Sans" }}
                            dangerouslySetInnerHTML={{
                              __html: parseHtml(activeBlog.post_content),
                            }}
                          />
                          {/* {parseHtml(activeBlog.post_content)} */}
                        </Typography>
                        <Typography>
                          <Typography
                          className="active-blog-read-more-typo"
                            style={{
                              width: "100%",
                              fontWeight: 700,
                              fontSize: "16px",
                              lineHeight: "25px",
                              fontFamily: "DM Sans",
                              color: "#EF3840",
                              cursor: "pointer",
                              justifyContent: "flex-start",
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                            onClick={() =>
                              handleImageClick(activeBlog.post_title)
                            }
                          >
                            Read More
                          </Typography>

                          {/* {isExpanded && (
                            <span
                              style={{
                                width: "100%",
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#EF3840",
                                cursor: "pointer",
                                justifyContent: "flex-start",
                                display: "flex",
                              }}
                              onClick={handleToggle}
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  handleToggle()
                                 }
                              }}
                            >
                              Show Less
                            </span>
                          )} */}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  {/* other blogs */}
                  <Box
                    className="article_other_blog"
                    sx={{
                      ...useStyles.customScrollbar,
                      width: "50%",
                      // height: "630px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1,
                      // marginTop:'1.5%'
                      //   overflowY: "auto",
                    }}
                  >
                    {blob &&
                      blob.length > 0 &&
                      blob
                        .filter(
                          (data) => data.post_title !== activeBlog.post_title
                        )
                        .map((blog, index) => {
                          return (
                            <Box
                              className="article_other_blog1_section"
                              key={index}
                              sx={{
                                width: "100%",
                                height: "auto",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                gap: 4,
                                marginBottom: "20px",
                                cursor: "pointer",
                                padding: "10px",
                              }}
                              onClick={() => handleImageClick(blog.post_title)}
                            >
                              {/* Image */}
                              <Box
                                className="Article_blog_list_image_Container"
                                sx={{
                                  width: "231px",
                                  height: "171px",
                                  borderRadius: "10px",
                                  overflow: "hidden",
                                  flexShrink: 0,
                                }}
                              >
                                <img
                                  className="Article_blog_list_image"
                                  src={blog.post_image}
                                  alt={blog && blog.post_title}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                  }}
                                />
                              </Box>

                              {/* Title and Description */}
                              <Box
                                className="Article_blog_list_image_Container"
                                sx={{
                                  width: "calc(75% - 140px)",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                  gap: "5px",
                                }}
                              >
                                <Typography
                                  className="article-blog-list-title-typo"
                                  sx={{
                                    fontWeight: 700,
                                    fontFamily: "DM Sans",
                                    fontSize: "20px",
                                    lineHeight: "25px",
                                    color: "#0E1415",
                                    maxHeight: "64px",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    textOverflow: "ellipsis",

                                    // whiteSpace: "normal",
                                    // overflowWrap: "break-word",
                                    // wordBreak: "break-word",
                                  }}
                                >
                                  {blog.post_title}
                                </Typography>
                                <Typography
                                  className="article-blog-list-body-typo"
                                  sx={{
                                    fontFamily: "DM Sans",
                                    fontSize: "16px",
                                    lineHeight: "25px",
                                    fontWeight: 500,
                                    color: "#0B0F11",
                                    // marginTop: "10px",
                                    maxHeight: "74px",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: "vertical",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {parseHtml(blog.post_content)}
                                </Typography>

                                {/* Blog Metadata */}

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: 1.5,
                                    marginTop: "10px",
                                    width: "100%",
                                  }}
                                ></Box>
                              </Box>
                            </Box>
                          );
                        })}
                  </Box>
                </Box>
              </Box>
)}
            {/* </> */}
          
        </Box>
        {/* <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',paddingBottom:'1%'}}>
      <Pagination
    count={Math.ceil(blob && blob.length / itemsPerPage)}
    page={currentPage}
    onChange={handlePageChange}
    variant="outlined"
    shape="rounded"
    size="large"

  />
      </Box> */}
      </Box>

      <CommonFooter />
    </LayoutComponent>
  );
};
