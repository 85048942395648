import React, { useEffect, useRef } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import { Box, Typography } from "@mui/material";
import PwrProductSearch from "./PwrProductSearch";
import OurProduct from "./OurProduct";
import ReliabilityAndQuality from "./ReliabilityAndQuality";
import ViewProducts from "./ViewProducts";
import HomeSwiper from "./HomeSwiper";
import CommonFooter from "../Footer/CommonFooter";
import ExpertTips from "./ExpertTips";
import { FrequentlyAskedQuest } from "./FrequentlyAskedQuest";
import {PartNoModal} from "./PartNoModal";
import { useDispatch, useSelector } from "react-redux";
import { CommonSetupActions } from "../../Slices/Commonslice";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const getInTouchRef = useRef(null);
  const faqRef = useRef(null);

  const {scrollAutomatic, faqScroll}=useSelector((state)=>state.CommonStore)
  const dispatch=useDispatch()
  const location = useLocation();

   useEffect(()=>{
      
    // dispatch(CommonSetupActions.resetState())

      if (!sessionStorage.getItem("hasRefreshed")) {
        sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
        window.location.reload();
      };
     
    },[])

 
    //   // Wait for the Constant Contact form to load
    //   const interval = setInterval(() => {
    //     const inputField = document.querySelector('.ctct-inline-form input.ctct-form-element');
    //     if (inputField) {
    //       inputField.setAttribute('placeholder', 'Enter your email');
    //       clearInterval(interval); // Stop checking once the input field is found and updated
    //     }
    //   }, 100); // Check every 100 milliseconds
    // });
  
  // Function to scroll to the GetInTouchForm component
  const scrollToContactUs = () => {
    getInTouchRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFAQ = () => {
    // if (faqRef?.current && faqScroll) {
    faqRef?.current.scrollIntoView({ behavior: "smooth" });
          dispatch(CommonSetupActions.SetscrollSameFaq(false));
    // }
    
  };

   const scrollToGetInTouch = () => {
      if (getInTouchRef?.current) {
        getInTouchRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
              dispatch(CommonSetupActions.SetscrollSamepage(false))
        
      }
    };
  
  const [open, setOpen] = React.useState(false);

  useEffect(()=>{
  if(faqScroll){
    scrollToFAQ()
  }
  },[faqScroll])

  // useEffect(()=>{
  // if(location.pathname==="/contact"){
  //   scrollToGetInTouch()
  // }
  // },[location])

  useEffect(()=>{
    dispatch(CommonSetupActions.resetState())
  },[])
  return (
    <LayoutComponent scrollToGetInTouch={scrollToFAQ}>
      <PwrProductSearch />
      {/* <div class="ctct-inline-form" data-form-id="df4ff37a-7a61-40bb-9ac1-e3c8f874a6c6"></div> */}
      <OurProduct />
       <ReliabilityAndQuality />
      <ViewProducts />
      <HomeSwiper />
      <ExpertTips/>
      <div className="freq_ref_div" ref={faqRef}><FrequentlyAskedQuest />
     </div>
      
        <PartNoModal open={false} setOpen={setOpen} />
      <CommonFooter scrollToGetInTouch={scrollToContactUs} />
    </LayoutComponent>
  );
};

export default HomePage;
