import React, { useEffect } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import CommonFooter from "./CommonFooter";
import routes from "../../AppRoutes/routes.json";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CommonSetupActions } from "../../Slices/Commonslice";
import { ApiLink } from "../Utils/Apilink";

const SiteMap = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { blog } = useSelector((state) => state.CommonStore);

  useEffect(() => {
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true");
      window.location.reload();
    }
  }, []);
  const fetchBlog = (searchitem) => {
    fetch(ApiLink + "articles", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {

        dispatch(CommonSetupActions.setBlog(response));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/[\s]+/g, "-")
      .replace(/[^\w\-]+/g, "");
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const handleImageClick = (postTitle, index) => {
    dispatch(CommonSetupActions.setActiveArticle(blog[index]));
    const slug = slugify(postTitle);
    sessionStorage.clear();
    navigate(`/articles/${slug}`);
    window.scrollTo({ top: 0 });
  };
  return (
    <LayoutComponent>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          className="main-war-state-box"
          sx={{
            width:'100%',
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            padding: "5%",
            flexWrap: "wrap",
          }}
        >
          {/* warranty statement heading */}
          <Box
            className="warr-head-box"
            sx={{
              // width:'909px',
              height: "42px",
              marginTop: "2%",
            }}
          >
            <Typography
              className="head-typo-access"
              sx={{
                fontFamily: "DM Sans",
                fontSize: "60px",
                fontWeight: 800,
                lineHeight: "50px",
                color: "#000000",
                textDecoration: "underline",
                textUnderlineOffset: "14px",
                textDecorationColor: "#E30000",
                textDecorationThickness: "3.5px",
              }}
            >
              Site Map
            </Typography>
            <hr
              className="war-hr-line"
              style={{
                width: "100%",
                marginTop: "2%",
                // border: "0.7px solid #D9D9D9",
              }}
            />
          </Box>

          <Box className="site-map-main-content" sx={{ width: "100%", marginTop: '4%' }}>
            <List>
              {/* Top-level: Pages */}
              <ListItem>
                <Typography
                  sx={{
                    fontSize: "30px",
                    color: "#55120B",
                    fontWeight: 600,
                    fontFamily: "DM Sans",
                  }}
                >
                  Pages
                </Typography>
              </ListItem>
              {/* Nested list under Pages */}
              <List component="div" disablePadding sx={{ pl: 4 }}>
                {/* Products */}
                <ListItem>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    • Products
                  </Typography>
                </ListItem>
                <List component="div" disablePadding sx={{ pl: 4 }}>
                  <ListItem>
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        "&:hover": {color:'#e30000' },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        navigate(routes.SteeringPump);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      • Pumps
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        "&:hover": {color:'#e30000' },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        navigate(routes.RackPinion);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      • Rack & Pinion
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        "&:hover": {color:'#e30000' },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        navigate(routes.SteeringGearBox);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      • Gear Box
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        "&:hover": {color:'#e30000' },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        navigate(routes.Reservoirs);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      • Reservoirs
                    </Typography>
                  </ListItem>
                </List>

                {/* Resources */}
                <ListItem>
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "20px",
                      fontWeight: 500,
                    }}
                  >
                    • Resources
                  </Typography>
                </ListItem>
                <List component="div" disablePadding sx={{ pl: 4 }}>
                  <ListItem>
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        "&:hover": {color:'#e30000' },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        navigate(routes.Articles);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      • Articles
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        "&:hover": {color:'#e30000' },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        navigate(routes.Videos);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      • Videos
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        "&:hover": {color:'#e30000' },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        navigate(routes.Warranty);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      • Warranty
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        "&:hover": {color:'#e30000' },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        navigate(routes.TechnicalInfo);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      • Technical Resources
                    </Typography>
                  </ListItem>
                </List>

                {/* Other items */}
                <ListItem>
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "20px",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {color:'#e30000' },

                    }}
                    onClick={() => {
                      sessionStorage.clear();
                      navigate(routes.Ecatlog);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    • Ecatalog
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "20px",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {color:'#e30000' },

                    }}
                    onClick={() => {
                      sessionStorage.clear();
                      navigate(routes.About);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    • About Us
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "20px",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {color:'#e30000' },

                    }}
                    onClick={() => {
                      sessionStorage.clear();
                      dispatch(CommonSetupActions.SetscrollAutomatic(true));
                      navigate(routes.Home);
                    }}
                  >
                    • Contact Us
                  </Typography>
                </ListItem>
              </List>

              {/* Top-level: Blogs */}
              <ListItem>
                <Typography
                  sx={{
                    fontSize: "30px",
                    color: "#55120B",
                    fontWeight: 600,
                    fontFamily: "DM Sans",
                    marginTop:'20px'
                  }}
                >
                  Blogs
                </Typography>
              </ListItem>
              {/* Nested list under Blogs */}
              <List component="div" disablePadding sx={{ pl: 4, width: "90%",marginTop:'10px' }}>
                <ListItem>
                  <Typography
                    onClick={() => handleImageClick(blog[0].post_title, 0)}
                    sx={{
                      fontFamily: "DM Sans",
                      "&:hover": { color: "red" },
                      cursor: "pointer",
                      textDecoration: "underline",
                      textAlign: "justify",
                      width: "97%",
                      overflowWrap: "break-word",
                    }}
                  >
                    •https://m.pwrsteer.com/articles/power-steering-system-inspection-a-step-by-step-guide-for-automotive-professionals
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    onClick={() => handleImageClick(blog[1].post_title, 1)}
                    sx={{
                      fontFamily: "DM Sans",
                      "&:hover": { color: "red" },
                      cursor: "pointer",
                      textDecoration: "underline",
                      textAlign: "justify",
                      width: "97%",
                      overflowWrap: "break-word",
                    }}
                  >
                    •https://m.pwrsteer.com/articles/remanufactured-vs-new-power-steering-racks-a-deep-dive-into-engineering-and-performance
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    onClick={() => handleImageClick(blog[2].post_title, 2)}
                    sx={{
                      fontFamily: "DM Sans",
                      "&:hover": { color: "red" },
                      cursor: "pointer",
                      textDecoration: "underline",
                      textAlign: "justify",
                      width: "97%",
                      overflowWrap: "break-word",
                    }}
                  >
                    •https://m.pwrsteer.com/articles/how-to-fix-a-power-steering-leak
                  </Typography>
                </ListItem>
              </List>
            </List>
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};

export default SiteMap;
